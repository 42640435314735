/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
  					path => router path
  					name => router name
  					component(lazy loading) => component to load
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import Vue from 'vue'
import Router from 'vue-router'
import {domains, sites} from '@multitenant/router'


Vue.use(Router)

// This is to avoid creating 'base' components to only include <router-view>
const RouterView = {
    // Inline declaration of a component that renders our <router-view>
    render: (c) => c('router-view', {
        class: 'view'

    })
};

const router = new Router({
  	mode: 'history',
	base: '/',
	routes: [

        // =============================================================================
        // Main Routes
        // =============================================================================
        {
            path: '/',
            name: 'dashboard',
            component: RouterView,
            children: [

				// =============================================================================
                // Onboarding
                // =============================================================================
                {
                    path: 'get-started',
                    name: 'onboard',
                    meta: { label: "Getting Started" },
                    component: () => import(/* webpackChunkName: "onboarding" */ './onboarding/onboarding.vue'),
					beforeEnter: (to, from, next) => {
						// reject the navigation
						if (!is_onboarding_completed && memberID >= 1) {
							next()
						} else {
							next({
								name: 'dashboard'
							});
						}
					},
                },


                // =============================================================================
                // Main Dashboard
                // =============================================================================
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    meta: { label: "Dashboard" },
                    component: () => import(/* webpackChunkName: "dashboard" */ './dashboard/dashboard.vue'),

                },

                // =============================================================================
                // Billing
                // =============================================================================
                {
                    path: 'billing',
                    name: 'billing',
                    meta: { label: "Billing" },
                    component: () => import(/* webpackChunkName: "billing" */ './billing/billing.vue'),

                },

				// =============================================================================
                // Global Settings
                // ==========================================================================
                {
                    path: 'settings',
                    name: 'settings',
                    meta: { label: "Settings" },
                    component: () => import(/* webpackChunkName: "settings" */ './settings/settings.vue'),
                }
            ]

        },

		// =============================================================================
		// Booking Routes
		// =============================================================================
		{
			path: '/booking',
            component: RouterView,
            children: [

				// =============================================================================
				// PAGES
				// =============================================================================



				// BOOKINGS
                {
					path: 'bookings',
                    component: {
                        render(c) {
                            return c('keep-alive',{ props: {include: 'BookingList'} }, [
                                c('router-view')
                            ]);
                        }
                    },
                    children: [

						// Bookings List
                        {
                            path: '',
                            name: 'bookings',
							meta: { label: "Bookings" },
                            component: () => import('./booking/bookings/List.vue'),
                        },

                        // CREATE BOOKING (Manual)
                        {
                            path: 'new-booking',
                            name: 'createBooking',
                            meta: { label: "Create Booking" },
                            component: () => import('./booking/bookings/manual/Manual.vue'),
                            props: true
                        },


                        // Booking Details
						{
							path: ':id',
							name: 'bookingDetails',
							component: () => import('./booking/bookings/BookingDetails.vue'),
						},



                    ]
                },

				// CALENDAR
				{
                    path: 'calendar',
                    name: 'calendar',
                    meta: { label: "Calendar" },
                    component: () => import('./booking/calendar/index.vue'),
                },

				// SERVICE PROVIDERS
                {
                    path: 'service-providers',
					name: 'teams',
                    meta: { label: "Service Providers" },
					component: () => import('./booking/teams/List.vue'),
                },

				// CUSTOMERS
                {
					path: 'customer',
                    component: RouterView,
                    children: [

						// Customers List
						{
							path: '/',
							name: 'customers',
							meta: { label: "Customers" },
							component: () => import('./booking/customers/List.vue'),
						},

						// Customer Index View
						{
							path: ':id',
							name: 'viewCustomer',
							component: () => import('./booking/customers/Customer.vue'),
						},
					]
				},

				// SERVICE PROVIDERS ACTIVITY
				// {
                //     path: 'activity',
                //     name: 'serviceProvidersActivity',
				// 	meta: { label: "Providers Activity" },
				// 	component: () => import('./booking/teams/activity/TeamsActivity.vue'),
                // },

				// PAYOUTS
				{
					path: 'reports',
                    component: RouterView,
                    children: [

						// Payouts List
						{
							path: '/',
							name: 'payouts',
							meta: { label: "Payouts" },
							component: () => import('./booking/reports/Reports.vue'),
						},

						// Payouts List by Team
						{
							path: 'team/:id',
							name: 'providerPayouts',
							component: () => import('./booking/reports/Team.vue'),
						},

						// Payouts History
						{
							path: 'history',
							name: 'paymentsHistory',
							meta: { label: "Payments History" },
							component: () => import('./booking/reports/PayoutsHistory.vue'),
						},
					],
				},

				// QUOTES
				{
					path: 'quotes',
					name: 'quotes',
                    meta: { label: "Quotes" },
					component: () => import('./booking/quotes/List.vue'),
				},

				// INVOICES
				{
					path: 'invoices',
					name: 'invoices',
                    meta: { label: "Invoices" },
					component: () => import('./booking/invoice/List.vue'),
				},

				// BOOKING SETTINGS
				{
					path: 'settings',
					component: RouterView,
					children: [

						// Configuration
						{
							path: '/',
							name: 'configuration',
							meta: { label: "Settings" },
							component: () => import(/* webpackChunkName: "bookingSettings" */'./booking/settings/Settings.vue'),
						},

						// Notifications History
						{
							path: 'notifications-history',
							name: 'notificationsHistory',
							meta: { label: "Notifications History" },
							component: () => import('./booking/settings/notifications/Logs.vue'),
						},
					]

				},

				// DISCOUNTS
				{
					path: 'discounts',
					name: 'discounts',
					meta: { label: "Discounts" },
					component: () => import('./booking/discounts/Discounts.vue'),
				},


				{
                    path: 'livestream',
                    name: 'livestream',
                    meta: { label: "Activity" },
                    component: () => import('./booking/livestream/index.vue'),
                },
				{
					path: 'services',
                    component: RouterView,
                    meta: { label: "Services" },
                    children: [
						{
							path: 'edit',
							name: 'editService',
                            beforeEnter: (to, from, next) => {
                                // reject the navigation
                                if (to.params.service_id) {
                                    // if (!is_onboarding_completed && memberID > 2950) {
                                    next()
                                } else {
                                    next({
                                        name: 'configuration'
                                    });
                                }
                            },
							component: () => import('./booking/services/Edit.vue'),
						},
					]
				},

				{
					path: 'categories',
                    component: RouterView,
                    meta: { label: "Categories" },

                    children: [
						{
							path: '',
							name: 'categories',
							component: () => import('./booking/categories/List.vue'),
						},
						{
							path: 'add',
							name: 'addCategory',
							component: () => import('./booking/categories/Add.vue'),
						},
						{
							path: 'edit/:id',
							name: 'editCategory',
							component: () => import('./booking/categories/Edit.vue'),
						},
					]
				},
				{
					path: 'pricing',
                    component: RouterView,
                    meta: { label: "Pricing Parameters" },

                    children: [
						{
							path: '',
							name: 'pricing',
							component: () => import('./booking/settings/pricing/List.vue'),
						},
						{
							path: 'add',
							name: 'addPrice',
							component: () => import('./booking/settings/pricing/Add.vue'),
						},
						{
							path: 'edit/:id',
							name: 'editPrice',
							component: () => import('./booking/settings/pricing/Edit.vue'),
						},
					]
				},
				{
					path: 'extras',
                    component: RouterView,
                    meta: { label: "Extras" },

                    children: [
						{
							path: '',
							name: 'extras',
							component: () => import('./booking/extras/List.vue'),
						},
						{
							path: 'add',
							name: 'addExtra',
							component: () => import('./booking/extras/Add.vue'),
						},
						{
							path: 'edit/:id',
							name: 'editExtra',
							component: () => import('./booking/extras/Edit.vue'),
						},
					]
				},

				{
					path: 'frequencies',
                    component: RouterView,
                    meta: { label: "Frequencies" },

                    children: [
						{
							path: '',
							name: 'frequencies',
							component: () => import('./booking/frequencies/List.vue'),
						},
						{
							path: 'add',
							name: 'addFrequency',
							component: () => import('./booking/frequencies/Add.vue'),
						},
						{
							path: 'edit/:id',
							name: 'editFrequency',
							component: () => import('./booking/frequencies/Edit.vue'),
						},
					]
				},



				// {
				// 	path: 'stripe',
				// 	name: 'stripe',
				// 	meta: { label: 'Stripe' },
				// 	component: () => import( './booking/settings/integrations/components/Stripe.vue'),
				// },
				// {
				// 	path: 'location',
				// 	name: 'location',
				// 	meta: { label: 'Location' },
				// 	component: () => import('./locations/List.vue'),
				// },
				// {
				// 	path: 'nicejob',
				// 	name: 'nicejob',
				// 	meta: { label: 'Nicejob'},
				// 	component: () => import( './booking/settings/integrations/components/Nicejob.vue'),
				// },

				// {
				// 	path: 'future-payments',
				// 	name: 'future-payments',
				// 	meta: { label: "Future Payments" },
				// 	component: () => import('./booking/reports/FuturePayments.vue'),
				// },
				// {
				// 	path: 'customer-portal',
				// 	name: 'customer-portal',
                //     meta: { label: "Customer Portal" },

                //     component: () => import(/* webpackChunkName: "bookingSettings" */'./booking/CustomerPortal.vue'),
				// },
				// {
				// 	path: 'tracking',
				// 	name: 'tracking',
                //     meta: { label: "Conversion Tracking" },
                //
                //     component: () => import('./booking/analytics/ThankYouSettings.vue'),
				// },
				{
					path: 'integrations',
					name: 'integrations',
					meta: { label: "Integrations" },
					component: () => import('./booking/settings/integrations/Integrations.vue'),
				},
				// {
				// 	path: 'time',
				// 	name: 'time-scheduling',
                //     meta: { label: "Time & Scheduling" },

                //     component: () => import(/* webpackChunkName: "bookingTimeSettings" */'./booking/settings/time/TimeSettings.vue'),
				// },

				// {
				// 	path: 'notifications',
                //     component: RouterView,
                //     meta: { label: "Notifications" },
                //     children: [
				// 		{
				// 			path: '',
				// 			name: 'NotificationDetails',
				// 			component: () => import('./booking/notifications/Notifications.vue'),
				// 		},

				// 	]
				// },
				// {
				// 	path: 'qa-section',
				// 	name: 'qa-section',
                //     meta: { label: "QA" },

                //     component: () => import(/* webpackChunkName: "bookingQA" */ './booking/Qalist.vue'),
				// },
				// {
				// 	path: 'forms',
				// 	name: 'forms',
                //     meta: { label: "Forms Customize" },
                //     component: () => import(/* webpackChunkName: "bookingWidget" */ './booking/book_settings/index.vue'),
				// }
			]
		},


        // =============================================================================
        // Marketing Routes
        // =============================================================================
        {
            path: '/marketing',
            component: RouterView,
            meta: { label: "Marketing" },

            children: [
                {
                    path: '',
                    name: 'marketing',
                    component: () => import('./marketing/index.vue'),
                },
                {
                    path: '?t=leads',
                    name: 'leads',
                    component: () => import('./marketing/index.vue'),
                },
                {
                    path: 'leads/list/:id',
                    name: 'leadsList',
                    meta: { label: "Leads" },
                    component: () => import('./marketing/leads/ListDetails.vue'),
                },
                {
                    path: 'analytics',
                    name: 'Analytics',
                    component: () => import('./marketing/analytics.vue'),
                },
                {
                    path: 'campaign',
                    component: RouterView,
                    children: [
                        {
                            path: 'new',
                            name: 'Campaign',
                            meta: { label: "Marketing" },
                            component: () => import('./marketing/email/new.vue'),
                        },
                        {
                            path: 'scheduled',
                            name: 'Scheduled',
                            component: () => import('./marketing/email/scheduled.vue'),
                        },
                        {
                            path: 'widgets',
                            name: 'widgets',
                            component: () => import('./marketing/email/widgets.vue'),
                        },
                    ]
                },
                {
                    path: 'widgets',
                    component: RouterView,
                    meta: { label: "Widgets" },
                    children: [
                        {
                            path: 'new',
                            name: 'NewWidget',
                            meta: { label: "New Widget" },
                            component: () => import('./marketing/widgets/new.vue'),
                        },
                        {
                            path: 'list',
                            name: 'WidgetsList',
                            meta: { label: "Widgets" },
                            component: () => import('./marketing/widgets/list.vue'),
                        }
                    ]
                },

            ]
        },

        // =============================================================================
        // Multitenant Routes
        // =============================================================================
        domains,
        sites,


		// Redirect to 404 page, if no match found
		{
			path: '*',
			redirect: { name: 'dashboard'}
		}
	],

})

router.beforeEach((to, from, next) => {

    // store prev route if exist
    if(from.name)
        to.params.previousRoute = from

    next();
})


export default router

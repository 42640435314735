
const actions = {

    setLocation ({ commit }, location) {
        if (location) {
            commit('SET_LOCATION', location);
            commit('SET_LOCATION_ID', location.location_id);
        } else {
            commit('SET_LOCATION', {});
            commit('SET_LOCATION_ID', '-1');
        }
    },


}

export default actions

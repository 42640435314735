export default {

    /*
    * Directive to autoresize el-inputs to its value size
    */

    bind (el, binding, vnode) {

        // this method adds shadow span to input
        // we'll use this span to calculate text width
        const  input = el.querySelector('.el-input__inner')
        const span = document.createElement('span')

        span.classList.add('resizeable-shadow');
        input.parentNode.insertBefore(span, input);

        resize(el, vnode.context)


    },

    componentUpdated (el, binding, vnode) {
        resize(el, vnode.context)
    },


}

function resize(el, instance){

    instance.$nextTick(()=>{

        const input = el.querySelector('.el-input__inner')
        const span = input.previousSibling

        span.style.fontSize =  window.getComputedStyle(input).fontSize;
        span.style.fontWeight =  window.getComputedStyle(input).fontWeight;


        span.textContent = input.value;


        let paddingleft =  parseFloat(window.getComputedStyle(input).paddingLeft);
        let paddingRight = parseFloat(window.getComputedStyle(input).paddingRight);

        let totalPadding = paddingRight + paddingleft + 2;

        // 50 is the padding of the inner input of the select

        input.style.width = `${span.getBoundingClientRect().width + totalPadding }px`;

    })
}


// This is to avoid creating 'base' components to only include <router-view>
const RouterView = {
    // Inline declaration of a component that renders our <router-view>
    render: (c) => c('router-view', {
        class: 'view'
    })
};

const domains = {
    path: '/domains',
    meta: { label: "Websites" },
    component: RouterView,
    children: [
        {
            path: '',
            name: 'my-domains',
            component: () => import ('@multitenant/views/domain-list.vue'),
        },
        {
            path: 'subdomain-selection',
            name: 'subdomain-selection',
            component: () => import ('@multitenant/views/subdomain-selection.vue'),
        },
        // {
        //   path: 'add-payment-method',
        //   name: 'payment-method',
        //   component: () => import('@multitenant/views/add-payment-method.vue'),
        // },
        {
            path: 'domain-selection',
            name: 'domain-selection',
            component: () => import ('@multitenant/views/domain-selection.vue'),
        },
        {
            path: 'domain-purchase/:id',
            name: 'domain-purchase',
            component: () => import ('@multitenant/views/domain-purchase.vue'),
        },

    ]

};

const sites = {
    path: '/websites',
    meta: { label: "Websites" },
    component: RouterView,
    children: [
        {
            path: '',
            name: 'my-sites',
            component: () => import ('@multitenant/views/sites-list.vue'),
        },
        {
            path: 'theme-selection',
            name: 'theme-selection',
            component: () => import ('@multitenant/views/theme-selection.vue'),
        },
        {
            path: 'theme-selected',
            name: 'theme-selected',
            component: () => import ('@multitenant/views/theme-selected.vue'),
        }
    ]
};

export {sites, domains};

const state = {

    location_id: "-1",
    current_location: {},
    onboardingOptions: {
        "basic": false,
        "service": false,
        "stripe": false,
        "website": false,
        "domain": false,
        "test": false
    }
};

export default state;

import moment from 'moment-timezone'
import exportFromJSON from "export-from-json";


export default {


    methods: {


        openExternal(url){
            location.href = url
        },

        openLinkInNewTab(url){
            var a = document.createElement('a');
            a.target="_blank";
            a.href=url;
            a.click();
        },

        exportDataFromJSON(data, newFileName, fileExportType) {
            if (!data) return;
            try {
              const fileName = newFileName || "exported-data";
              const exportType = exportFromJSON.types[fileExportType || "xls"];
              exportFromJSON({ data, fileName, exportType });
            } catch (e) {
              throw new Error("Parsing failed!");
            }
        },

        logout(){
            this.$http('/logout',
            {
                method: 'POST',
                baseURL: ''
            })
            .then((res)=>{
                location.reload();
            })
        },

        timeAgo(time) {
            moment.updateLocale('en', {
                relativeTime: {
                    future: "in %s",
                    past: "%s ago",
                    s: number=>number + "s ago",
                    ss: '%ds ago',
                    m: "1m ago",
                    mm: "%dm ago",
                    h: "1h ago",
                    hh: "%dh ago",
                    d: "1d ago",
                    dd: "%dd ago",
                    M: "a month ago",
                    MM: "%d months ago",
                    y: "a year ago",
                    yy: "%d years ago"
                }
            });

            let secondsElapsed = moment().diff(time, 'seconds');
            let dayStart = moment("2018-01-01").startOf('day').seconds(secondsElapsed);

            if (secondsElapsed > 300) {
                return moment(time).fromNow(true);
            } else if (secondsElapsed < 60) {
                return dayStart.format('s') + 's ago';
            } else {
                return dayStart.format('m') + 'm ago';
            }
        },

        formatAddress(value) {
            if (value) {
                let address = JSON.parse(value)
                
                if (address.street_address == null) {
                    return 'N/A'
                } else {
                    return address.apt_number != 'N/A' ? '<div class="el-table__cell-title capitalize-first text-truncate">' + address.street_address + ', ' + address.apt_number + '</div> <div class="el-table__cell-description capitalize-first  text-truncate">' + address.city + ', ' + (address.state ? address.state  + ', ' : '') + address.zipcode + '</div>' : '<div class="el-table__cell-title capitalize-first text-truncate">' + address.street_address + '</div> <div class="el-table__cell-description capitalize-first text-truncate">' + address.city + ', ' + (address.state ? address.state + ', ' : '') + address.zipcode + '</div>'
                }
            }

            return 'N/A'
        },


},

    computed: {


        getGreeting() {

            const currentHour = this.$moment().hours();

            if (currentHour >= 5 && currentHour < 12) {
                return 'Good morning';
            } else if (currentHour >= 12 && currentHour < 18) {
                return 'Good afternoon';
            } else {
                return 'Good evening';
            }
        },

        csrfToken() {
            var selector;

            if (typeof window !== 'undefined' && window['Laravel'] && window['Laravel'].csrfToken) {
                return window['Laravel'].csrfToken;
            } else if (typeof document !== 'undefined' && typeof document.querySelector === 'function' && (selector = document.querySelector('meta[name="csrf-token"]'))) {
                return selector.getAttribute('content');
            }

            return null;
        },



        currentRouteLabel() {
            const matchedRoute = this.$route.matched.find(route => route.meta.label)
            return matchedRoute ? matchedRoute.meta.label : 'Title'
        },

        currentDayDate()
        {
            return moment().clone().startOf('day').format('YYYY-MM-DD')
        },

        yesterdayDayDate() {
            return moment().clone().subtract(1, "days").format("YYYY-MM-DD");
        },

        currencySymbol() {
            return this.$serviceCurrency ? this.$serviceCurrency.currency[2] : ''
        },

        phonePrefix() {
            return this.$serviceCurrency ? this.$serviceCurrency.phone_prefix : ''
        }

    },
}

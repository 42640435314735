// import router from "../router";

const mutations = {

    SET_LOCATION(state, value) {
        state.current_location = value;
    },

    SET_LOCATION_ID(state, value) {
        state.location_id = value;
    },

    SET_ONBOARDING_OPTIONS(state, value) {
        state.onboardingOptions = value;
    },

}

export default mutations
